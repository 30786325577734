* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.wrapper .main::-webkit-scrollbar {
  background-color: #f5f5f5;
  border-radius: 10px;
  width: 7px;
  height: 10px;
}

.wrapper .main::-webkit-scrollbar-thumb {
  background-color: #333;
  border-radius: 10px;
}

.wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #e9e7da;
  text-align: center;
}

.wrapper .header > h1,
.wrapper .header > p {
  text-align: center;
  padding: 15px 75px 5px;
}

.wrapper .header > h1 {
  font-size: 58px;
  line-height: 60px;
  color: #453621;
  font-weight: 100;
  font-style: normal;
  text-decoration: none;
}

.wrapper .header > p {
  font-size: 16px;
  line-height: 28px;
  color: #333333;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

.wrapper .header .filters strong {
  font-size: 16px;
  color: #333333;
  font-weight: 500;
  font-style: normal;
  text-decoration: none;
  margin-top: 15px;
}

.wrapper .header .filters > span {
  background-color: #dcdacc;
  color: #333333;
  font-size: 15px;
  padding: 8px 15px;
  position: relative;
  top: 10px;
}

.info-icon {
  width: 30px;
  position: relative;
  top: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.wrapper .main {
  margin-top: 25px;
  max-height: 900px;
  overflow-y: scroll;
  padding-top: 15px;
}

.wrapper table {
  border: 0;
  border-spacing: 0;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.wrapper table th {
  text-align: left;
  background-color: #37322b;
  color: #fff;
  padding: 15px !important;
  font-size: 15px;
  line-height: 20px;
  font-weight: normal;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 2.24px;
}

.wrapper table td,
.wrapper table th {
  border: 0;
  border-spacing: 0;
  padding: 15px;
  text-align: left;
}

.wrapper table td {
  font-size: 14px;
  color: #333;
}

.wrapper table tr.platinum-sponsor td:first-child,
.wrapper table tr.gold-sponsor td:first-child,
.wrapper table tr.silver-sponsor td:first-child {
  padding-left: 0;
}

.wrapper table tr:nth-child(odd) {
  background-color: #f7f5f1;
}

.wrapper table tr:hover {
  background-color: #ddd;
}

.wrapper table a {
  color: #333;
  text-decoration: none;
}

.filters {
  display: flex;
  justify-content: center;
  align-items: center;
}

.platinum-sponsor,
.gold-sponsor {
  transform: scale(1.02);
}

.platinum-sponsor,
.gold-sponsor,
.silver-sponsor {
  padding: 0;
}

.platinum-sponsor,
.platinum-sponsor > td {
  background-color: #2e3845 !important;
}

.gold-sponsor,
.gold-sponsor > td {
  background-color: #d2ac47 !important;
}

.silver-sponsor,
.silver-sponsor > td {
  background-color: #ffffff !important;
}

.platinum-sponsor td,
.gold-sponsor td,
.silver-sponsor td {
  padding: 0 !important;
  position: relative;
}

.platinum-sponsor td,
.gold-sponsor td {
  border-bottom: 15px solid #e7e7e7 !important;
}

.platinum-sponsor td,
.platinum-sponsor a,
.gold-sponsor td,
.gold-sponsor a {
  color: #fff !important;
}

.featured-sponsor {
  max-width: fit-content;
  background-color: #37322b;
  padding: 5px 10px;
  line-height: 20px;
  color: #e9e7da;
  font-weight: 500;
  display: flex;
  gap: 10px;
  position: absolute;
  top: 15px;
  z-index: 9999;
  align-items: center;
}

.featured-sponsor img {
  object-fit: contain;
}

.business-partner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.business-partner > div {
  width: calc(50% - 10px);
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
}

.platinum-sponsor .business-partner > div:first-child {
  position: relative;
}

.play-btn {
  width: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.business-partner img:not(.company-img) {
  margin: 10px auto;
  max-width: 150px;
}

.business-partner .company-img {
  margin: 0 10px 0 0;
  object-fit: cover;
  max-width: 100%;
  height: 225px;
  cursor: pointer;
}

.business-partner .sponsor-logo {
  margin: 10px 0 0 5px !important;
}

.business-partner img.platinum-sticker {
  max-width: 75px;
  margin: 0 0 0 5px !important;
}

.rating {
  margin-bottom: 15px;
}

.rating > div {
  margin: 10px 0;
}

.rating strong {
  font-size: 22px;
  font-weight: normal;
}

.rating span {
  color: #e9e7da;
  margin-left: 10px;
}

.silver-sponsor .rating span {
  color: #2d3944;
}

.sponsor-link.link-platinum {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.sponsor-link.link-platinum span:first-child {
  background-color: rgb(233, 231, 218);
  color: #000 !important;
  font-size: 16px;
  padding: 10px 10px 10px 20px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  color: #fff;
  font-weight: 600;
  line-height: 19px;
  display: flex;
  align-items: center;
  height: 60px;
}

.sponsor-link.link-platinum span:last-child {
  background-color: #37322b;
  padding: 10px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  color: #fff;
  font-size: 25px;
  display: flex;
  align-items: center;
}

.sponsor-link.link-platinum:hover span:first-child {
  background-color: #37322b;
  color: #fff !important;
}

.sponsor-link:not(.link-platinum) {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
}

.gold-sponsor .sponsor-link:hover {
  color: #e9e7da !important;
  text-decoration: underline;
}

.silver-sponsor .sponsor-link:hover {
  color: #597c9b !important;
  text-decoration: underline;
}

.description img {
  max-width: 150px;
  margin: 10px auto;
}

.description {
  font-size: 14px;
  line-height: 20px;
  padding: 5px;
  font-weight: 100;
}

.description h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin: 10px 0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99999;
}

.modal-main {
  position: fixed;
  background-color: #37322b;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  padding: 20px 30px;
}

.modal-main:not(.modal-iframe) {
  max-height: 445px;
  overflow-y: scroll;
}

.modal-main::-webkit-scrollbar {
  background-color: #333;
  border-radius: 10px;
  width: 7px;
  height: 10px;
}

.modal-main::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.modal-main.modal-iframe {
  background-color: #343434;
  max-width: 900px;
  padding: 30px;
}

.modal-main p {
  color: #fff;
  text-align: left;
  font-size: 14px;
  line-height: 28px;
  max-height: 420px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.cancel-modal {
  width: 24px;
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
}

.css-b62m3t-container {
  width: 350px;
}

.img-border {
  background-color: #fff;
  padding: 5px;
}

@media screen and (max-width: 767px) {
  .wrapper .header .filters strong {
    display: block;
    margin-top: 15px;
  }

  .wrapper .header .filters select {
    margin-top: 10px;
  }

  .wrapper .header .filters > span {
    display: block;
    clear: both;
    max-width: 80%;
    margin: 5px auto 10px;
  }

  .filters {
    flex-direction: column;
  }

  iframe {
    max-height: 250px;
  }
}
